<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logo-psychomeasure-icon.png')"
              max-height="64px"
              max-width="64px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold title-header">
              <span class="light">Psycho</span>measure
            </h2>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <p class="subheading">
            A password recovery email will be sent to the registered account
          </p>
          <v-form @keyup.enter="submit">
            <v-text-field
              @keyup.enter="submit"
              label="Email"
              placeholder="john@example.com"
              type="text"
              v-model="username"
              required
              :error-messages="userNameErrors"
              @input="$v.username.$touch()"
              @blur="$v.username.$touch()"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel">Cancel</v-btn>
          <v-btn color="primary" @click.prevent="submit" :disabled="!valid">
            Recover Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="
        require(`@/assets/images/misc/mask-${
          $vuetify.theme.dark ? 'dark' : 'light'
        }.png`)
      "
    />

    <!-- tree -->
    <!--
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
    -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mapActions, mapState } from "vuex"
import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  validations: {
    username: { required, email },
  },
  data() {
    return {
      username: "",
    }
  },
  computed: {
    userNameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.email && errors.push("Must be valid e-mail")
      !this.$v.username.required && errors.push("E-mail is required.")
      return errors
    },
    valid() {
      return !this.$v.$anyError
    },
    ...mapState({ loginError: state => state.users.logInError }),
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.passwordRecovery(this.username)
      }
    },
    ...mapActions("users", ["passwordRecovery"]),
  },
}
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
